import initializeFTConfig from './useFeatureToggleHelpers/useFeatureToggleHelpers';
import { FTUtilsReturnedType } from './useFeatureToggle.types';
import { useLocation, useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { useConfig } from 'src/api/config';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';

const useFeatureToggle = (): Record<FT, FTUtilsReturnedType> | Record<string, never> | undefined => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { search } = useLocation();
  const { data: config } = useConfig(gid);

  const initFT = config?.feature_toggles && initializeFTConfig(config?.feature_toggles, search, gid, flow);

  if (initFT) {
    return config?.feature_toggles.reduce((a, v) => {
      const feature = initFT(v.name);
      return { ...a, [feature.name]: feature };
    }, {});
  }
};

export default useFeatureToggle;
