export enum EXPERIMENT_VARIANTS_ENUM {
  CONTROL = 'control_variation',
  TEST_A = 'test_variation_a',
  TEST_B = 'test_variation_b',
  TEST_C = 'test_variation_c'
}

export type EXPERIMENT_REPORT_VARIANTS_TYPE = '0' | '1' | '2' | '3';

export enum FEATURE_TOGGLES_IDS_ENUM {
  //FEATURES
  INACTIVE_SESSION_FT = 'cx.1512.session_timeout_feature',
  HIDE_GOOGLE_MAP_FT = 'cx.ff.3002.hide_google_map',
  ZERO_QUOTES_SOFT_STOP_FT = 'cx.3143.zero_quotes_soft_stop',
  KIN_INSURANCE = 'cx.ff.3234.kin_options',
  //EXPERIMENTS
  AF_PRESCORE_PREMIUM = 'cx.exp.2763.prescore_predicted_premium',
  DTQ_AF_PRESCORE_PREMIUM = 'cx.exp.2889.dtq_prescore_predicted_premium',
  AF_TO_DP = 'cx.exp.3081.af_digital_profile',
  FREEDOM_SF_TO_SINGLE_QUOTE = 'cx.exp.3116.sf_to_af_freedom'
}
