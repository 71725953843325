/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Page from 'src/components/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Footer from 'src/components/Footer/Footer';
import FloridaKinLanding from 'src/components/Quotes/FloridaKinLanding/FloridaKinLanding';
import { useLocation, useParams } from 'react-router-dom';
import { LocationStateKeys } from './FloridaNoOptions.types';
import { isObjectInstanceOfLocationState } from './FloridaNoOptions.utils';
import useFeatureToggle from 'src/hooks/useFeatureToggle/useFeatureToggle';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import FloridaNoCallLanding from 'src/components/FloridaNoCallLanding/FloridaNoCallLanding';
import { useConfig } from 'src/api/config';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';

import { DefaultLocation } from 'src/interfaces/IPage';

const FloridaNoOptions: React.FC = () => {
  const { state } = useLocation();
  const { flow, gid } = useParams() as DefaultLocation;
  const features = useFeatureToggle();
  const kinInsurance = features?.[FT.KIN_INSURANCE];
  const { isLoading } = useConfig(gid);

  useEffect(() => {
    if (!isLoading) {
      analytics.page(SEGMENT.PAGES.NO_OPTIONS);

      if (kinInsurance?.isEnabled) {
        analytics.track(SEGMENT.KIN_INSURANCE_OPTION_VIEWED, gid, flow);
      }
    }
  }, [isLoading, kinInsurance?.isEnabled, gid, flow]);

  useEffect(() => {
    const ineligibilityReason = isObjectInstanceOfLocationState(state) && state[LocationStateKeys.IneligibleType];
    if (!!ineligibilityReason) {
      analytics.track(SEGMENT.NO_OPTIONS_INELIGIBILITY_TYPE_REDIRECTED, gid, flow, {
        type: ineligibilityReason
      });
    }
  }, [flow, gid, state]);

  const floridaComponentProps = useMemo(() => {
    return isObjectInstanceOfLocationState(state)
      ? {
          [LocationStateKeys.UserName]: state[LocationStateKeys.UserName],
          [LocationStateKeys.ContactPhone]: state[LocationStateKeys.ContactPhone]
        }
      : {
          [LocationStateKeys.UserName]: '',
          [LocationStateKeys.ContactPhone]: ''
        };
  }, [state]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Page fullHeight>
      <PageHeader page={SEGMENT.PAGES.NO_OPTIONS} />
      <main>
        {kinInsurance?.isEnabled ? (
          <FloridaKinLanding {...floridaComponentProps} />
        ) : (
          <FloridaNoCallLanding {...floridaComponentProps} />
        )}
      </main>
      <Footer page={SEGMENT.PAGES.NO_OPTIONS} />
    </Page>
  );
};

export default FloridaNoOptions;
