/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuotesHeroContentSavingsProps } from './QuotesHeroContentSavings.types';
import {
  descriptionCSS,
  headingCSS,
  imageCSS,
  savingsValueCSS,
  savingsValueSuffixCSS
} from './QuotesHeroContentSavings.style';
import { contentHeroCSS, descriptionTextHeroCSS } from 'src/components/Quotes/QuotesPageHero/QuotesPageHero.style';
import confettiImg from 'src/assets/svg/quotes-hero-savings-image.svg';
import Format from 'src/utils/format';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const QuotesHeroContentSavings: React.FC<QuotesHeroContentSavingsProps> = ({
  customCSS,
  savingValue,
  firstName = 'Hey'
}) => {
  return (
    <div css={[contentHeroCSS, customCSS]}>
      <div>
        <div css={headingCSS} data-testid={AUTOMATION_TEST_IDS.QUOTES_PAGE.HEADING}>
          {firstName}, check out these savings
        </div>
        <img src={confettiImg} css={imageCSS} alt="Confetti" />
        <div css={[descriptionTextHeroCSS, descriptionCSS]}>
          <div>You can save up to</div>
          <div css={savingsValueCSS}>
            {Format.money(savingValue, 0, '$')}
            <span css={savingsValueSuffixCSS}>/yr</span>
          </div>
          <div>
            Celebrate! Take a look at all of the quotes Matic has found for you and find the one that works best.
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesHeroContentSavings;
