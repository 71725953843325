/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState, Fragment } from 'react';

import { QuoteSelectData, QuotesProps, SelectedQuoteState } from 'src/components/Quotes/Quotes.types';
import { carriersList, dividerCSS, faq, quotesContainer, talkToAdvisor } from 'src/components/Quotes/Quotes.style';
import Container from 'src/kit/Container/Container';
import {
  contentWrapperCSS,
  listBlockCSS,
  listWrapperCSS,
  otherCarriersCSS,
  quoteCardCSS,
  containerCSS,
  userProfileDetailsCSS,
  calMeNowWrapperCSS,
  heroContainerCSS
} from './StandardHouseQuotes.style';
import DividerIcon from 'src/assets/svg/curvy-divider.svg';
import TalkToAdvisorSection from 'src/components/Quotes/TalkToAdvisorSection/TalkToAdvisorSection';
import FAQ from 'src/components/FAQ/FAQ';
import QuotesCarriers from 'src/components/Quotes/QuotesCarriers/QuotesCarriers';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import QuotesUserProfile from './QuotesUserProfile/QuotesUserProfile';
import useQuotes from 'src/api/quotes/useQuotes';
import { HomeQuote, HomeQuotesResponse } from 'src/interfaces/IQuotes';
import { AddressAnswer, Option } from 'src/interfaces/IQuestion';
import { DISCOUNTS, KNOCKOUT_QUESTIONS } from 'src/constants/hardcoded';
import QuotesPageHero from 'src/components/Quotes/QuotesPageHero/QuotesPageHero';
import useConfig from 'src/api/config/useConfig';
import ManualQuoteCard from 'src/components/Quotes/QuoteCard/ManualQuoteCard/ManualQuoteCard';
import HomeCard from 'src/components/Quotes/QuoteCard/HomeCard/HomeCard';
import { getMultiPolicyDiscountTag } from 'src/constants/quoteTag';
import useResponsive from 'src/hooks/useResponsive';
import QuoteDetailsDialog from 'src/components/Quotes/QuoteDetailsDialog/QuoteDetailsDialog';
import Format from 'src/utils/format';
import { getHomeQuotesCoveragesGroups, getPremiumLabelByType } from 'src/utils/quotesPage';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import QuotesOtherCarriers from 'src/components/Quotes/QuotesOtherCarriers/QuotesOtherCarriers';
import { getQuotesHeroType, getUnderwritingQuestionsAppliedKeys } from './StandardHouseQuotes.utils';
import { faqHorizontalScrollFixContainer } from 'src/theme/shared-styles';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import useFeatureToggle from 'src/hooks/useFeatureToggle/useFeatureToggle';
import { useHomeDetails } from 'src/api/homeDetails';
import useQuestions from 'src/api/questions/useQuestions';
import CallMeNowCardComponent from 'src/components/CallMeNowCardComponent/CallMeNowCardComponent';
import EditInfoSideModal from 'src/components/EditInfoSideModal/EditIfnoSideModal';
import MockQuotesLoader from 'src/components/MockQuotesLoader/MockQuotesLoader';
import { getCurrentQuotePageVariation } from './StandardHouseQuotes.utils';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';
import { AccordBundleMsg } from 'src/components/BundleMsgComponents/AccordBundleMsg/AccordBundleMsg';

const StandardHouseQuotes: React.FC<QuotesProps> = ({ selectQuote, shouldRenderNonSavingsVariation }) => {
  const navigate = useCustomNavigate();
  const { isMobile, isDesktop } = useResponsive();
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data: questions } = useQuestions(gid);
  const { data: homeDetails } = useHomeDetails(gid);
  const { data } = useQuotes<HomeQuotesResponse>(flow, gid);
  const [selectedQuote, setSelectedQuote] = useState<SelectedQuoteState<HomeQuote> | null>(null);
  const [shouldOpenPersonalizeQuoteModal, setShouldOpenPersonalizeQuoteModal] = useState(false);
  const [isMobileDetailsOpen, setIsMobileDetailsOpen] = useState(false);
  const [bundleToggleOn, setBundleToggleOn] = useState(true);
  const [shouldRenderQuotesReload, setShouldRenderQuotesReload] = useState(false);
  const features = useFeatureToggle();

  const isPolicyHolderVariation =
    questions?.answers?.person_is_policyholder === 'yes' || !!questions?.answers?.person_is_policyholder_identified;

  const quotesData = data as HomeQuotesResponse;
  const agentPhone = isPolicyHolderVariation ? config?.partner?.cst_phone : config?.partner?.agent_phone;
  const noQuotes = quotesData?.quotes?.length === 0;
  const confirmationPagePath = `/${flow}/${gid}/confirmation`;
  const manualQuotes = quotesData?.manual_quotes;

  const { carrier_name } = questions?.answers || {};
  const { property_address, stories, year_built, square_feet } = homeDetails || {};

  const first_name = questions?.answers.person_first_name as string;
  const last_name = questions?.answers.person_last_name as string;
  const MPDQuoteAvailable = quotesData.quotes.some(quote => quote.multi_policy_discount_premium);
  const shouldShowAccordBundleMsg: boolean =
    flow === Flows.Accord && (!!quotesData?.quotes.length || !!manualQuotes?.length);

  //only for control bundle
  const isBndlTgglPageVariation =
    flow === Flows.AllFunnel && !noQuotes && quotesData?.landlord_quotes_page === false && MPDQuoteAvailable;

  //for common components (bundle control & bundle test )
  const afBundleToggleOn = flow === Flows.AllFunnel && bundleToggleOn;

  const savingsQuotesPage =
    !!quotesData?.recommended_quote_savings &&
    quotesData.recommended_quote_savings > 0 &&
    !!quotesData.current_policy &&
    !shouldRenderNonSavingsVariation;

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.QUOTES, {
      flow_type: flow,
      session_gid: gid
    });
  }, [flow, gid]);

  useEffect(() => {
    const kinIsurance = features?.[FT.KIN_INSURANCE];
    if (
      kinIsurance?.isEnabled &&
      flow === Flows.Florida &&
      quotesData?.quotes &&
      quotesData?.quotes.length === 0 &&
      quotesData?.manual_quotes.length === 0
    ) {
      navigate(`/${flow}/${gid}/no-options`, {
        state: {
          userName: questions?.answers?.person_first_name
        }
      });
    }
  }, [flow, quotesData, gid, navigate, questions, config, features]);

  useEffect(() => {
    const quotesRecap =
      quotesData?.quotes?.map(quote => ({
        premium: quote.premium.value,
        carrier_key: quote.carrier.key,
        savings_amount: quote.saving_value || null,
        gid: quote.gid
      })) || [];

    const manualQuotesRecap =
      manualQuotes?.map(quote => ({
        carrier_key: quote.carrier.key,
        gid: quote.gid
      })) || [];

    const quotesArrayToReport = [...quotesRecap, ...manualQuotesRecap];

    analytics.track(SEGMENT.QUOTES_LIST_VIEWED, gid, flow, {
      number_of_quotes: quotesData.quotes.length,
      in_the_money: !!quotesData.recommended_quote_savings,
      landlord: !!quotesData.landlord_quotes_page,
      quotes: quotesArrayToReport
    });
  }, [quotesData, gid, flow, manualQuotes]);

  useEffect(() => {
    const isOnlyManualQuotePageVariation = manualQuotes?.length > 0;
    const isQuoteListReturned = quotesData?.quotes.length > 0;
    const quotePageVariation = getCurrentQuotePageVariation(
      savingsQuotesPage,
      noQuotes,
      isBndlTgglPageVariation,
      !!quotesData?.landlord_quotes_page,
      isOnlyManualQuotePageVariation,
      isQuoteListReturned
    );

    analytics.track(SEGMENT.HOME_QUOTE_PAGE_VARIATION_SEEN, gid, flow, {
      quote_page_variation: quotePageVariation
    });
  }, [quotesData, gid, flow, isBndlTgglPageVariation, noQuotes, savingsQuotesPage, manualQuotes]);

  useEffect(() => {
    if (shouldRenderQuotesReload) {
      setInterval(() => {
        setShouldRenderQuotesReload(false);
      }, 7000);
    }
  }, [shouldRenderQuotesReload, setShouldRenderQuotesReload]);

  useEffect(() => {
    setBundleToggleOn(questions?.answers?.person_has_core_bundle_interest === 'yes');
  }, [isBndlTgglPageVariation, questions?.answers?.person_has_core_bundle_interest]);

  const getQuoteSelectData = (quote: HomeQuote): QuoteSelectData => {
    return {
      gid: quote.gid,
      carrier: quote.carrier,
      onlineBindUrl: quote.online_bind_url,
      flow,
      premium_value: quote.premium.value,
      savingsAmount: quote.saving_value!,
      digital_profile_url: quote.digital_profile_url
    };
  };

  const trackQuoteReviewed = async (quote: HomeQuote, ordinal: number) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, flow, {
      carrier_key: quote.carrier.key,
      ordinal
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.QuoteDetailsClicked, gid);
    } catch {}
  };

  const onTalkToAdvisorClick = async (ordinal: number, clickReason: string) => {
    analytics.track(SEGMENT.TALK_TO_AN_ADVISOR_CLICKED, gid, flow, {
      ordinal,
      click_reason: clickReason
    });

    analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, flow, {
      page: SEGMENT.PAGES_KEY.QUOTES,
      location: SEGMENT.LOCATIONS.QUOTES_HERO
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
    } catch {}

    navigate(confirmationPagePath);
  };

  const onCardViewed = useCallback(
    (quote: HomeQuote, index: number) => {
      if (quote.online_bind_url) {
        analytics.track(SEGMENT.ONLINE_BIND_OFFERED, gid, flow, {
          ordinal: index + 1,
          carrier_key: quote.carrier.key
        });
      }
    },
    [flow, gid]
  );

  const onHeroActionClick = async () => {
    if (!isPolicyHolderVariation) {
      analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, flow, {
        page: SEGMENT.PAGES_KEY.QUOTES,
        location: SEGMENT.LOCATIONS.QUOTES_HERO
      });

      try {
        await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
      } catch {}

      navigate(confirmationPagePath);
    } else {
      analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
        page: SEGMENT.PAGES_KEY.QUOTES,
        location: SEGMENT.LOCATIONS.QUOTES_HERO
      });
    }
  };

  const onHeroCallToAgentClick = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.QUOTES_BANNER,
      page: SEGMENT.PAGES_KEY.QUOTES
    });
  };

  const showMobileDetails = (quote: HomeQuote, ordinal: number) => {
    trackQuoteReviewed(quote, ordinal);
    setSelectedQuote({ quote, ordinal });
    setIsMobileDetailsOpen(o => !o);
  };

  const onShowQuoteDetailClick = (quote: HomeQuote, ordinal: number) => {
    if (isMobile) {
      showMobileDetails(quote, ordinal);
    } else {
      trackQuoteReviewed(quote, ordinal);
    }
  };

  const onQuoteSelectClick = (quote: HomeQuote, ordinal: number) => {
    selectQuote(ordinal, getQuoteSelectData(quote));
  };

  const onQuoteSelectFromDetailsPage = () => {
    if (selectedQuote) {
      const { quote, ordinal } = selectedQuote;
      selectQuote(ordinal, getQuoteSelectData(quote));
    }
  };

  const onOtherCarriersActionClick = async () => {
    analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, flow, {
      page: SEGMENT.PAGES_KEY.QUOTES,
      location: SEGMENT.LOCATIONS.NO_QUOTES_CARRIERS_LIST
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
    } catch {}

    navigate(confirmationPagePath);
  };

  const getQuoteCoverTag = ({ discount }: { discount: number | null }) => {
    if (afBundleToggleOn && discount) {
      return [getMultiPolicyDiscountTag(discount)];
    }

    return null;
  };

  const onPersonalizedModalButtonClick = (shouldOpen: boolean) => {
    if (shouldOpen) {
      setShouldOpenPersonalizeQuoteModal(true);
      analytics.track(SEGMENT.PERSONALIZED_QUOTE_BUTTON_CLICKED, gid, flow);
    } else {
      setShouldOpenPersonalizeQuoteModal(false);
      analytics.track(SEGMENT.PERSONALIZED_QUOTE_MODAL_CLOSED, gid, flow);
    }
  };

  const onPersonalizedQuoteInfoUpdate = () => {
    setShouldOpenPersonalizeQuoteModal(false);
    setShouldRenderQuotesReload(true);
  };

  const getMobileDetailsPopup = () => {
    if (selectedQuote) {
      const { quote: q } = selectedQuote as { quote: HomeQuote };
      const bundleSavingsValue = q.premium.value - (15 * q.premium.value) / 100;
      const annualPremium = afBundleToggleOn ? bundleSavingsValue : q.premium.value;

      return (
        <QuoteDetailsDialog
          logo={{
            url: q.carrier.logo_url,
            label: q.carrier.name
          }}
          price={{
            value: Format.money(annualPremium, 0, '$'),
            label: getPremiumLabelByType(q.premium.type)
          }}
          contentComponent={<QuoteCoverages groups={getHomeQuotesCoveragesGroups(q.coverages)} expandable />}
          onClose={() => {
            setIsMobileDetailsOpen(false);
            setTimeout(() => setSelectedQuote(null), 100);
          }}
          onActionClick={onQuoteSelectFromDetailsPage}
          isOpen={isMobileDetailsOpen}
        />
      );
    }
  };

  const getPremiumDiscount = (quote: HomeQuote) =>
    (quote.multi_policy_discount_percentage! * quote.premium.value) / 100;

  const sortBundleQuotes = (arr: HomeQuote[]) => {
    const nonMultiPolicyQuotes = arr.filter(q => !q.multi_policy_discount_premium);
    const multiPolicyQuotes = arr.filter(q => q.multi_policy_discount_premium);

    if (bundleToggleOn && isBndlTgglPageVariation) {
      multiPolicyQuotes.sort((a, b) => {
        const aValue = a.premium.value - getPremiumDiscount(a);
        const bValue = b.premium.value - getPremiumDiscount(b);

        return aValue - bValue;
      });

      return [...multiPolicyQuotes, ...nonMultiPolicyQuotes];
    }

    return arr;
  };

  return (
    <div aria-live="polite">
      <Container dense customCSS={heroContainerCSS(!isDesktop)}>
        {
          <QuotesPageHero
            type={getQuotesHeroType({
              noQuotes,
              savingsQuotesPage,
              landlordQuotesPage: !!data?.landlord_quotes_page,
              accordQuotesPage: flow === Flows.Accord,
              negativeSavingsTestVariation: false,
              isPolicyHolderVariation: isPolicyHolderVariation
            })}
            data={{
              savingValue: quotesData?.recommended_quote_savings,
              firstQuotePremium: quotesData?.quotes[0]?.premium.value,
              agentPhone,
              firstName: first_name,
              carrierName: quotesData?.quotes[0]?.carrier.name,
              carrierLogo: quotesData?.quotes[0]?.carrier.logo_url
            }}
            onCallToAgentClick={onHeroCallToAgentClick}
            onActionClick={onHeroActionClick}
            isBndlTgglPageVariation={isBndlTgglPageVariation}
            setAFToggleOn={setBundleToggleOn}
          />
        }
      </Container>

      <Container dense customCSS={[quotesContainer, containerCSS]}>
        <div css={listBlockCSS}>
          <div css={userProfileDetailsCSS}>
            <QuotesUserProfile
              firstName={first_name}
              lastName={last_name}
              carrierName={(carrier_name as Option)?.label}
              currentPolicy={quotesData?.current_policy}
              homeDetails={{
                yearBuilt: year_built as string,
                squareFeet: square_feet as string,
                stories: stories as string
              }}
              address={property_address as AddressAnswer}
              propertyInformation={getUnderwritingQuestionsAppliedKeys(KNOCKOUT_QUESTIONS, questions?.answers)}
              discounts={getUnderwritingQuestionsAppliedKeys(DISCOUNTS, questions?.answers)}
            />
          </div>

          {shouldRenderQuotesReload ? (
            <MockQuotesLoader />
          ) : (
            <Fragment>
              <div css={contentWrapperCSS}>
                <div css={listWrapperCSS}>
                  {shouldShowAccordBundleMsg && !!agentPhone && (
                    <AccordBundleMsg
                      contactPhone={agentPhone}
                      onContactPhoneClick={() => {
                        analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
                          page: SEGMENT.PAGES_KEY.QUOTES,
                          location: SEGMENT.LOCATIONS.BUNDLE_MESSAGE_ACCORD
                        });
                      }}
                    />
                  )}

                  {sortBundleQuotes(quotesData?.quotes).map((quote, index) => (
                    <HomeCard
                      key={quote.gid}
                      customCSS={quoteCardCSS(index === 0)}
                      index={index}
                      tags={getQuoteCoverTag({
                        discount: quote.multi_policy_discount_percentage
                      })}
                      quote={quote}
                      highlightPremium={index === 0 && savingsQuotesPage}
                      onCardViewed={onCardViewed}
                      onShowDetailsClicked={() => onShowQuoteDetailClick(quote, index + 1)}
                      onQuoteSelect={() => onQuoteSelectClick(quote, index + 1)}
                      useMultiPolicyDiscount
                    />
                  ))}

                  {manualQuotes?.map((quote, index) => (
                    <ManualQuoteCard
                      customCSS={quoteCardCSS(false)}
                      ordinal={index + 1}
                      key={quote.carrier.key}
                      quote={quote}
                      contactPhone={agentPhone}
                      fullWidth={isMobile}
                      showLessVariant
                    />
                  ))}

                  {isPolicyHolderVariation && (
                    <CallMeNowCardComponent
                      contactNumber={agentPhone}
                      customCSS={calMeNowWrapperCSS}
                      isPolicyHolderVariation={isPolicyHolderVariation}
                    />
                  )}
                </div>

                {noQuotes && !isPolicyHolderVariation && (
                  <QuotesOtherCarriers customCSS={otherCarriersCSS} onActionClick={onOtherCarriersActionClick} />
                )}
              </div>
            </Fragment>
          )}
        </div>
        {!shouldRenderQuotesReload && (
          <Fragment>
            {!isPolicyHolderVariation && (
              <Fragment>
                <img css={dividerCSS} src={DividerIcon} alt="Divider" />
                <TalkToAdvisorSection customCSS={talkToAdvisor} onTalkToAdvisorClick={onTalkToAdvisorClick} />
              </Fragment>
            )}

            <div css={faqHorizontalScrollFixContainer}>
              <FAQ page={SEGMENT.PAGES.QUOTES} customCSS={faq} />
            </div>

            <QuotesCarriers customCSS={carriersList} />
          </Fragment>
        )}
      </Container>

      {shouldOpenPersonalizeQuoteModal && (
        <EditInfoSideModal
          isOpen={shouldOpenPersonalizeQuoteModal}
          onPersonalizedQuoteInfoUpdate={onPersonalizedQuoteInfoUpdate}
          onModalClosed={() => onPersonalizedModalButtonClick(false)}
        />
      )}
      {getMobileDetailsPopup()}
    </div>
  );
};

export default StandardHouseQuotes;
