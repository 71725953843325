import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';

import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import AutoQuotes from './AutoQuotes/AutoQuotes';
import AutoBundleQuotes from './AutoBundleQuotes/AutoBundleQuotes';
import HomeBundleQuotes from './HomeBundleQuotes/HomeBundleQuotes';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import quotesService from 'src/api/quotes/quotes.service';
import useQuotes from 'src/api/quotes/useQuotes';
import { BundleStatus, HomeQuote, QuotesResponseStatus, BundleStatusToReport } from 'src/interfaces/IQuotes';
import { isObjectInstanceOfLocationState, PossibleQuotesResponse, QuoteSelectData } from './Quotes.types';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import isMaticUrl from 'src/utils/isMaticUrl';
import StandardHouseQuotes from './StandardHouseQuotes/StandardHouseQuotes';
import useConfig from 'src/api/config/useConfig';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import useFeatureToggle from 'src/hooks/useFeatureToggle/useFeatureToggle';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import useIdleTimer from 'src/hooks/useIdleManualTimer';
import { getInactivityTime } from 'src/utils/inactivityTime';
import { useQuestions } from 'src/api/questions';
import AfSingleQuote from './AfSingleQuote/AfSingleQuote';

interface QuotesProps {
  shouldRenderSingleQuoteVariation: boolean;
}

const Quotes: React.FC<QuotesProps> = ({ shouldRenderSingleQuoteVariation }) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { state } = useLocation();
  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const { isLoading: isQuestionsLoading, data: questions } = useQuestions(gid);
  const navigate = useCustomNavigate();
  const [isSessionInactive, setIsSessionInactive] = useState(false);
  const sessionTimeout = getInactivityTime(config?.lock_timeout);
  const [startTimer, resetTimer] = useIdleTimer(sessionTimeout, setIsSessionInactive);
  const [shouldRenderOLBOnConfirmation, setShouldRenderOLBOnConfirmation] = useState(false);
  const [shouldRedirectToConfirmation, setShouldRedirectToConfirmation] = useState(false);
  const [isFreedomSFVariation, setIsFreedomSFVariation] = useState(false);

  const [selectedCarrier, setSelectedCarrier] = useState('');

  const { data: quotes, isLoading: isQuotesLoading } = useQuotes<PossibleQuotesResponse>(flow, gid);
  const isPolicyHolderVariation =
    questions?.answers?.person_is_policyholder === 'yes' || !!questions?.answers?.person_is_policyholder_identified;
  const shouldRenderNonSavingsVariation = isObjectInstanceOfLocationState(state) && state.showNonSavings;

  const features = useFeatureToggle();
  const inactiveSessionFT = useMemo(() => features?.[FT.INACTIVE_SESSION_FT], [features]);
  const shouldStartInactiveTimeout =
    [Flows.Auto, Flows.AutoBundle, Flows.Home].includes(flow) && inactiveSessionFT?.isEnabled;

  const onRedirectToSessionTimeout = useCallback(() => {
    resetTimer();
    window.location.reload();
  }, [resetTimer]);

  const areHomeQuotesArray = (quotes: any): quotes is HomeQuote[] => {
    return 'length' in quotes;
  };

  useEffect(() => {
    if (shouldStartInactiveTimeout) {
      startTimer();
    }
  }, [flow, startTimer, shouldStartInactiveTimeout]);

  useEffect(() => {
    if (isSessionInactive) {
      onRedirectToSessionTimeout();
    }
  }, [isSessionInactive, onRedirectToSessionTimeout]);

  useEffect(() => {
    const freedomSFToSingleQuote = features?.[FT.FREEDOM_SF_TO_SINGLE_QUOTE];

    freedomSFToSingleQuote?.isEnabled && setIsFreedomSFVariation(!freedomSFToSingleQuote?.isControlVariation);
  }, [features, flow, quotes, shouldRenderSingleQuoteVariation]);

  useEffect(() => {
    if (
      quotes?.quotes &&
      areHomeQuotesArray(quotes?.quotes) &&
      !!quotes?.quotes.length &&
      quotes?.bundle_status !== BundleStatus.Success
    ) {
      const quotesToRecap = quotes?.quotes.slice(0, 3);

      const quotesRecap = quotesToRecap.map(q => ({
        premium: q.premium.value,
        carrier_key: q.carrier?.key,
        savings_amount: q.saving_value
      }));

      analytics.track(SEGMENT.QUOTE_RECAP, gid, flow, {
        quotes: quotesRecap
      });
    }
  }, [flow, gid, quotes]);

  const getBundleStatus = () =>
    flow === Flows.AutoBundle ? BundleStatusToReport.Auto : flow === Flows.Home ? BundleStatusToReport.Home : null;

  const renderQuotesComponent = () => {
    const props = {
      selectQuote,
      setShouldRenderOLBOnConfirmation
    };

    switch (flow) {
      case Flows.Auto:
        return quotes?.bundle_status ? <AutoBundleQuotes {...props} /> : <AutoQuotes {...props} />;
      case Flows.AutoBundle:
        return <AutoBundleQuotes {...props} />;
      case Flows.Home:
      case Flows.Accord:
      case Flows.Lookup:
      case Flows.Florida:
        return quotes?.bundle_status === BundleStatus.Success || quotes?.bundle_status === BundleStatus.HomeOnly ? (
          <HomeBundleQuotes {...props} />
        ) : (
          <StandardHouseQuotes {...props} />
        );
      case Flows.AllFunnel:
        return shouldRenderSingleQuoteVariation || isFreedomSFVariation ? (
          <AfSingleQuote selectQuote={selectQuote} isFreedomSFVariation={isFreedomSFVariation} />
        ) : (
          <StandardHouseQuotes shouldRenderNonSavingsVariation={shouldRenderNonSavingsVariation} {...props} />
        );
    }
  };

  const trackSelectQuote = (
    ordinal: number,
    quotesData: QuoteSelectData,
    bundledQuoteData?: QuoteSelectData | null
  ) => {
    analytics.track(SEGMENT.QUOTE_SELECTED, gid, quotesData.flow, {
      quote_gid: quotesData.gid,
      carrier_key: quotesData.carrier.key,
      ...(quotesData.coverageType && { coverage_tier: quotesData.coverageType }),
      ...(quotesData.savingsAmount && { savings_amount: quotesData.savingsAmount }),
      ordinal,
      ...(bundledQuoteData && { bundle_status: getBundleStatus() }),
      bundle_only: false,
      ...(quotesData.premium_value && { premium_value: quotesData.premium_value })
    });

    if (bundledQuoteData) {
      analytics.track(SEGMENT.QUOTE_SELECTED, gid, bundledQuoteData.flow, {
        carrier_key: bundledQuoteData.carrier.key,
        quote_gid: bundledQuoteData.gid,
        ordinal,
        bundle_status: getBundleStatus(),
        premium_value: bundledQuoteData.premium_value,
        bundle_only: false
      });
    }
  };

  const selectQuote = async (
    ordinal: number,
    quoteData: QuoteSelectData,
    bundledQuoteData?: QuoteSelectData | null
  ) => {
    trackSelectQuote(ordinal, quoteData, bundledQuoteData);
    setSelectedCarrier(quoteData.carrier.name);

    const quoteGids = [quoteData.gid];

    if (bundledQuoteData?.gid) {
      quoteGids.push(bundledQuoteData.gid);
    }

    if (quoteData.onlineBindUrl && !bundledQuoteData) {
      if (!isMaticUrl(quoteData.onlineBindUrl)) {
        throw Error(`Online bind url is not correct. Url: ${quoteData.onlineBindUrl}`);
      }
      try {
        await quotesService.selectQuote(flow, gid, quoteGids);
      } catch {}
      window.open(quoteData.onlineBindUrl, '_self');
      return;
    }

    if (quoteData.digital_profile_url) {
      try {
        await quotesService.selectQuote(flow, gid, quoteGids);
      } catch {}

      analytics.track(SEGMENT.DIGITAL_PROFILE_URL_REDIRECTED, gid, flow);
      window.open(quoteData.digital_profile_url, '_self');
      return;
    }

    try {
      await quotesService.selectQuote(flow, gid, quoteGids);
      setShouldRedirectToConfirmation(true);
    } catch {}
  };

  const getQuotesForConfirmation = () => {
    if (areHomeQuotesArray(quotes?.quotes)) {
      return quotes?.quotes;
    }

    return;
  };

  if (shouldRedirectToConfirmation) {
    const quotesArrayForConfirmation = getQuotesForConfirmation();

    return (
      <Navigate
        to={`/${flow}/${gid}/confirmation`}
        {...(quotesArrayForConfirmation?.length &&
          shouldRenderOLBOnConfirmation && {
            state: {
              olbQuotesArray: quotesArrayForConfirmation.filter(quote => !!quote?.online_bind_url),
              currentQuote: quotesArrayForConfirmation[0]
            }
          })}
        {...(isPolicyHolderVariation && {
          state: {
            isPolicyHolderVariation: isPolicyHolderVariation,
            carrierName: selectedCarrier
          }
        })}
      />
    );
  }

  if (
    isQuotesLoading ||
    isQuestionsLoading ||
    isConfigLoading ||
    (isFreedomSFVariation && quotes?.status === QuotesResponseStatus.InProgress)
  ) {
    return <FullPageLoader />;
  }

  if (!quotes?.status || quotes?.status === QuotesResponseStatus.InProgress) {
    navigate(`/${flow}/${gid}/interstitial`);
    return null;
  }

  return <Fragment>{renderQuotesComponent()}</Fragment>;
};

export default Quotes;
