import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useConfig } from 'src/api/config';
import { DefaultLocation } from 'src/interfaces/IPage';
import analytics from 'src/utils/analytics';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';

const useInitIntegrations = (): void => {
  const { gid } = useParams() as Partial<DefaultLocation>;
  const { data: config, isLoading } = useConfig(gid);

  useEffect(() => {
    const { segment, sentry, full_story: fs } = config?.integrations || {};
    if (!isLoading) {
      segment?.enabled && analytics.init(segment?.key);

      if (!isLoading && sentry?.environment === 'production' && sentry?.dsn) {
        Sentry.init({
          dsn: sentry.dsn,
          environment: sentry.environment,
          release: import.meta.env.VITE_APP_SENTRY_RELEASE,
          autoSessionTracking: false,
          integrations: fs ? [new SentryFullStory(fs.org_slug, { client: FullStory })] : []
        });
      }
    }
  }, [isLoading, config]);
};

export default useInitIntegrations;
